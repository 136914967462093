import moment from "moment";
import { Link } from "react-router-dom";
import { color } from "../../../constant/color";
import { IoCalendarOutline } from "react-icons/io5";
import DataTable, { TableColumn } from "react-data-table-component";
import React from "react";
import { tableCustomStyles } from "../../../HelperFunctions";

const SubmeterTable = ({ data, loading }) => {
  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
    },
    {
      name: "Meter Id",
      selector: (row) => row.meterid,
      sortable: true,
      grow: 10,
      center: true,
    },
    // {
    //   name: "Company alias",
    //   selector: (row) => row.companyalias,
    //   sortable: true,
    //   center: true,
    //   grow: 8,
    // },
    // {
    //   name: "Serial Id",
    //   selector: (row) => row.serialid,
    //   sortable: true,
    //   center: true,
    //   grow: 4,
    // },
    {
      name: "Device Model",
      selector: (row) => row.devicemodel,
      sortable: true,
      center: true,
      grow: 8,
    },
    {
      name: "Active status",
      cell: (row) => (
        <div
          className="rounded p-2"
          style={{
            background:
              row.activestatus === "active" ? "#6fed6f33" : "#ffa58733",
            color: row.activestatus === "inactive" ? color.red : color.green,
          }}
        >
          {row.activestatus === "active" ? "active" : "suspended"}
        </div>
      ),
      center: true,
      grow: 8,
    },
    {
      name: "Saasexpirydate",
      cell: (row) => (
        <div
          style={{
            fontSize: 12,
          }}
          className="mx-2"
        >
          {moment(row.saasexpirydate).format("YYYY-MM-DD")}
        </div>
      ),
      sortable: true,
      center: true,
      grow: 8,
    },
    {
      name: "Device Type",
      selector: (row) => row.devicetype,
      sortable: true,
      center: true,
      grow: 6,
    },
    // {
    //   name: "Timezone",
    //   selector: (row) => row.timezone,
    //   sortable: true,
    //   center: true,
    //   grow: 6,
    // },
    {
      name: "Datecreated",
      cell: (row) => (
        <div
          style={{
            fontSize: 12,
          }}
          className="mx-2"
        >
          {moment(row.datecreated).format("YYYY-MM-DD")}
        </div>
      ),
      sortable: true,
      center: true,
      grow: 8,
    },
    {
      name: "Dateassigned",
      cell: (row) => (
        <div
          style={{
            fontSize: 12,
          }}
          className="mx-2"
        >
          {moment(row.dateassigned).format("YYYY-MM-DD")}
        </div>
      ),
      sortable: true,
      center: true,
      grow: 8,
    },
    {
      name: "Action",
      cell: (row) => (
        <Link
          to={`/submeter/${row.meterid}`}
          className="text-decoration-underline"
        >
          View more
        </Link>
      ),
      button: true,
    },
  ];

  return (
    <div className="w-100">
      <DataTable
        customStyles={tableCustomStyles}
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
      />
    </div>
  );
};

export default SubmeterTable;
