import { color } from "../../../constant/color";
import Button from "../../Button";
import CloseIcon from "../../CloseIcon";
import React, { useEffect, useState } from "react";
import "./style.css";
import toast from "react-hot-toast";
import axios from "../../../utils/axios";
import { companyController } from "../../../Controller/CompanyController";
import { DatePicker, Select } from "antd";
import { meterController } from "../../../Controller/meterController";
import moment from "moment";
import { timeZone } from "./data";
import { flattenArr } from "../../../HelperFunctions";
import SubmeterTable from "../Table/SubmeterTable";

const AddSingleAndIssue = ({ closeBtn, refreshBtn }) => {
  const { Option } = Select;
  const [serialid, setSerialid] = useState("");
  const [meterid, setMeterid] = useState("");
  const [metermodel, setMeterModel] = useState([]);
  const [timezone, settimezone] = useState("");
  const [selectedmodel, setselectedmodel] = useState("");
  const [loading, setLoading] = useState(false);
  const [company, setcompany] = useState([]);
  const [saasexpirydate, setsaasexpirydate] = useState("");
  const [selectedCompany, setselectedCompany] = useState("");
  const [submeters, setsubmeters] = useState([]);
  const [response, setresponse] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [test, settest] = useState("");
  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post("/management/getallcompanydata", {});
        let result = companyController(res);

        if (result.type !== "success") {
          toast.error(result.message);
          setLoading(false);
          return;
        }
        setcompany(result.message.body);
      } catch (err) {
        toast.err(err.message);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post(
          `/devicemanagement/submeter/getallmetermodels`,
          {}
        );

        let result = meterController(res);
        if (result.message !== "success") {
          toast.error(result.message);
        }

        setMeterModel(result.data.body.map((i) => i.devicemodel));
      } catch (err) {
        toast.error(err.message);
      }
    })();
  }, []);

  const handleSubmeter = async () => {
    try {
      if (
        !serialid ||
        !meterid ||
        !test ||
        !timezone ||
        !saasexpirydate ||
        !selectedCompany
      )
        return toast.error("All values are required");
      setLoading((prev) => true);
      let res = await axios.post(
        "/devicemanagement/submeter/createandissuesubmetersingle",
        {
          meterid: meterid,
          serialid: serialid,
          metermodel: test,
          saasexpiration: saasexpirydate,
          companyalias: selectedCompany,
          timezone,
        }
      );
      let result = companyController(res);
      if (result.type !== "success") {
        toast[result.type](result.message);
        setLoading((prev) => false);
        return;
      }
      toast.success("Submeter created successfully.");
      console.log(result.message);
      setLoading((prev) => false);
      refreshBtn((prev) => !prev);
      if (!Object.keys(result.message).length) {
        toast.success("Submeter issued successfully");
      }
      closeBtn(false);
    } catch (err) {
      toast.error(err.message);
      setLoading((prev) => false);
    }
  };
  return (
    <div className="backdrop">
      {showTable && <SubmeterTable data={response} closeModal={closeBtn} />}
      <div
        className="submeter-issue_modal animate__animated animate__fadeInUp bg-white rounded-3"
        style={{
          display: showTable ? "none" : "block",
        }}
      >
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between">
            <div className="pt-4 modal-title pb-2 text-center">
              Fill the input fields to a create and issue a submeter
            </div>
            <div>
              <div className="" onClick={() => closeBtn(false)}>
                <CloseIcon />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <div class="company-entry">
                <label for="exampleInputEmail1" class="form-label">
                  Select a company
                </label>

                <div className="w-100">
                  <Select
                    className="form-input w-100"
                    placeholder={"select a meter model"}
                    onChange={(e) => setselectedCompany(e)}
                    size="large"
                  >
                    {company.map((i, index) => (
                      <Option key={index} value={i.companyalias}>
                        {i.companyname}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div class="mb-3 company-entry">
                <label for="exampleInputEmail1" class="form-label">
                  Serial id
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="enter serial id..."
                  value={serialid}
                  onChange={(e) => setSerialid(e.target.value)}
                />
              </div>
            </div>
            <div className="col-6">
              <div class="mb-3 company-entry">
                <label for="exampleInputEmail1" class="form-label">
                  DCU
                </label>
                <input
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="enter a DCU ID"
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength) {
                      e.target.value = e.target.value.slice(
                        0,
                        e.target.maxLength
                      );
                    }
                  }}
                  maxLength="11"
                  type={"text"}
                  value={meterid}
                  onChange={(e) => setMeterid(e.target.value)}
                />
              </div>
            </div>
            <div className="col-6">
              <div class="mb-3 company-entry">
                <label for="exampleInputEmail1" class="form-label">
                  Select a meter model
                </label>
                <div className="w-100">
                  <Select
                    className="form-input w-100"
                    placeholder={"select a meter model"}
                    onChange={(e) => settest(e)}
                    size="large"
                    style={{
                      fontSize: "13px",
                    }}
                  >
                    {metermodel.map((i) => (
                      <Option key={i} value={i.devicemodel}>
                        {i.devicemodel}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div class="mb-3 company-entry">
                <label for="exampleInputEmail1" class="form-label">
                  Select timezone
                </label>

                <div className="w-100">
                  <Select
                    className="form-input w-100"
                    placeholder={"select a meter model"}
                    onChange={(e) => settimezone(e)}
                    size="large"
                    style={{
                      fontSize: "13px",
                    }}
                  >
                    {timeZone.map((i) => (
                      <Option key={i.id} value={i.value}>
                        {i.value}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div class="mb-3 company-entry">
                <label for="exampleInputEmail1" class="form-label">
                  Submeter expiration date
                </label>
                <div className="w-100">
                  <DatePicker
                    allowClear
                    size="large"
                    showTime
                    style={{
                      width: "100%",
                      fontSize: "13px",
                    }}
                    className="form-control"
                    onChange={(e) =>
                      setsaasexpirydate(
                        moment(e._d).format("YYYY-MM-DD HH:mm:ss")
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row my-4">
            <div className="col-2" onClick={() => handleSubmeter()}>
              <Button
                text={"Create"}
                color={color.white}
                bgColor={color.primary}
                fontSize={"13px"}
                height={"43px"}
                status={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSingleAndIssue;
