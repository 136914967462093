import { color } from "../../../constant/color";
import Button from "../../Button";
import CloseIcon from "../../CloseIcon";
import React, { useState } from "react";
import "./style.css";
import toast from "react-hot-toast";
import axios from "../../../utils/axios";
import { companyController } from "../../../Controller/CompanyController";
import { Select } from "antd";

const AddModel = ({ closeBtn, refreshBtn }) => {
  const [phasetype, setPhaseType] = useState("");
  const [numberofchannels, setNumberOfChannels] = useState("");
  const [powerLimit, setPowerLimit] = useState("");
  const [meterModel, setMeterModel] = useState("");
  const [error, setError] = useState("");
  const [deviceType, setDeviceType] = useState("");
  const [loading, setLoading] = useState(false);
  const [inputSources, setInputSources] = useState(0);
  const { Option } = Select;

  const handleModel = async () => {
    try {
      const meterModelPattern = /^(.*)-(XG|RF)$/; // Regex to check if meterModel ends with -XG or -RF

      if (
        !deviceType ||
        !phasetype ||
        !inputSources ||
        !numberofchannels ||
        !meterModel ||
        !powerLimit
      ) {
        return toast.error("All values are required");
      }

      if (!meterModelPattern.test(meterModel)) {
        return toast.error('Meter model must end with "-XG" or "-RF"');
      }

      setLoading(true);

      let res = await axios.post(
        `/devicemanagement/submeter/createmetermodel`,
        {
          metermodel: meterModel,
          phasetype: phasetype,
          numberofchannels: numberofchannels,
          powerlimit: powerLimit,
          devicetype: deviceType,
          numberofsources: inputSources,
        }
      );
      let result = companyController(res);
      if (result.type !== "success") {
        toast[result.type](result.message);
        setLoading((prev) => false);
        return;
      }
      toast.success("Meter model added successfully");
      refreshBtn((prev) => !prev);
      setLoading((prev) => false);
      closeBtn((prev) => false);
    } catch (err) {
      toast.error(err.message);
      setLoading(false);
    }
  };
  return (
    <div className="backdrop">
      <div className="meter-modal__cover animate__animated animate__fadeInUp bg-white rounded-3">
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between">
            <div className="pt-4 modal-title pb-2">
              Fill the input fields to create a meter model
            </div>
            <div>
              <div onClick={() => closeBtn(false)}>
                <CloseIcon />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-6 col-xl-6 col-xxl-6">
              <div class="mb-3 company-entry">
                <label for="exampleInputEmail1" class="form-label">
                  Model name
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder=""
                  value={meterModel}
                  onChange={(e) => setMeterModel(e.target.value)}
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-xl-6 col-xxl-6">
              <div class="mb-3 company-entry">
                <label for="exampleInputEmail1" class="form-label">
                  Phase type
                </label>
                <div className="form-control">
                  <Select
                    style={{
                      width: "100%",
                    }}
                    placeholder={"single or three"}
                    bordered={false}
                    onChange={(e) => setPhaseType((prev) => e)}
                  >
                    {["single", "three"].map((item, i) => (
                      <Option key={i} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-xl-6 col-xxl-6">
              <div class="mb-3 company-entry">
                <label for="exampleInputEmail1" class="form-label">
                  Number of channel
                </label>
                <input
                  type="number"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="number of channels"
                  value={numberofchannels}
                  onChange={(e) => setNumberOfChannels(e.target.value)}
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-xl-6 col-xxl-6">
              <div class="mb-3 company-entry">
                <label for="exampleInputEmail1" class="form-label">
                  Power limit
                </label>
                <input
                  type="number"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder=""
                  value={powerLimit}
                  onChange={(e) => setPowerLimit(e.target.value)}
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-xl-6 col-xxl-6">
              <div class="mb-3 company-entry">
                <label for="exampleInputEmail1" class="form-label">
                  Number of sources
                </label>
                <input
                  type="number"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder=""
                  value={inputSources}
                  onChange={(e) => setInputSources(e.target.value)}
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-xl-6 col-xxl-6">
              <div class="mb-3 company-entry">
                <label for="exampleInputEmail1" class="form-label">
                  Meter type.
                </label>
                <div className="form-control">
                  <Select
                    style={{
                      width: "100%",
                    }}
                    bordered={false}
                    onChange={(e) => setDeviceType((prev) => e)}
                  >
                    {["Submeter", "Panel"].map((item, i) => (
                      <Option key={i} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            <div className="row d-flex align-items-center justify-conten">
              <div className="my-4 col-3" onClick={() => handleModel()}>
                <Button
                  text={"Create"}
                  color={color.white}
                  bgColor={color.primary}
                  fontSize={"15px"}
                  height={"45px"}
                  status={loading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddModel;
